<template>
    <Toast/>
    <div class="grid">
        <div class="col-12">
            <div class="card p-fluid">
                <h5>Adataim</h5>
                <div class="field">
                    <label for="name1">Név</label>
                    <InputText type="text" v-model="admin.name" />
                </div>
                <div class="field">
                    <label for="email1">Email</label>
                    <InputText type="text" v-model="admin.email" />
                </div>

                <div v-if="!showPassword">
                  <Button label="Jelszó módosítás" @click="showPassword = true" class="w-50" ></Button>
                </div>
                <div class="field" v-else>
                    <div class="mb-3">
                      <label>Jelszó</label>
                      <Password id="password" v-model.trim="admin.password" :required="true" weakLabel="Gyenge"
                                promptLabel="Új jelszó" mediumLabel="Közepes" strongLabel="Erős">
                          <template #footer>
                              <Divider />
                              <p class="mt-2">Követelmények</p>
                              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                  <li>Minimum 1 kisbetű</li>
                                  <li>Minimum 1 nagybetű</li>
                                  <li>Minimum 1 szám</li>
                                  <li>Minimum 8 karakter</li>
                              </ul>
                          </template>
                      </Password>
                    </div>
                    <div>
                      <label>Jelszó megerősítése</label>
                      <Password id="passwordConfirm" v-model.trim="admin.passwordConfirm" :required="true" :feedback="false"
                                promptLabel="Új jelszó megerősítése" >
                      </Password>
                      <p class="p-error">{{ errors.passwordConfirm }}</p>
                    </div>
                </div>
                <Button label="Mentés" icon="pi pi-check" class="p-button-success mt-3" @click="save" :loading="loading" :disabled="loading" />
            </div>
        </div>
    </div>
</template>

<script>
import AdminService from "@/service/AdminService";

export default {
    name: "Profile",
    adminService: null,
    data() {
        return {
            admin: {},
            loading: false,
            showPassword: false,
            errors: {
              passwordConfirm: ''
            }
        }
    },
    created() {
        this.admin = JSON.parse(localStorage.getItem('userData'));
        this.adminService = new AdminService();
    },
    computed: {
      checkConfirmedPassword() {
        return this.admin.password === this.admin.passwordConfirm;
      },
    },
    methods: {
      save() {
          let data = {
            name: this.admin.name,
            email: this.admin.email,
          };

          if (this.showPassword) {
            if (!this.checkConfirmedPassword) {
              this.errors.passwordConfirm = 'A két jelszó nem egyezik!';
              return false;
            }

            data.password = this.admin.password;
          }

          this.loading = true;

          this.adminService.update(this.admin.id, data).then((response) => {
              this.admin = response.data;
              localStorage.setItem('userData', JSON.stringify(this.admin));

              this.$toast.add({
                  severity: 'success',
                  summary: 'Sikeres művelet!',
                  detail: 'Az adatok sikeresen módosultak.',
                  life: 5000
              });

              this.showPassword = false;
              this.loading = false;
          }).catch(e => {
              this.$toast.add({
                  severity: "error",
                  summary: "Sikertelen művelet!",
                  detail: e.response.data.error,
                  life: 6000,
              });

              this.loading = false;
          });
      }
    }
}
</script>